export const WUBRG = Object.freeze(["W", "U", "B", "R", "G"]);
// WUBRG-ordered color combinations
// https://boardgames.stackexchange.com/questions/54638
// https://www.reddit.com/r/magicTCG/comments/5ybsgw/guide_mtg_colour_pie_for_dummies_allies_enemies/
const AllyColors = Object.freeze(["WU", "UB", "BR", "RG", "GW"]);
const EnemyColors = Object.freeze(["WB", "UR", "BG", "RW", "GU"]);
// Shards: primary color + two allies, sorted by primary/middle color
const ShardColors = Object.freeze(["GWU", "WUB", "UBR", "BRG", "RGW"]);
// Wedges: primary color + two enemies, sorted by primary/middle color
const WedgeColors = Object.freeze(["RWB", "GUR", "WBG", "URW", "BGU"]);
// Four-color combinations
const FourColors = Object.freeze(["WUBR", "UBRG", "BRGW", "RGWU", "GWUB"]);

/**
 * An ordered list of all explicit WUBRG-ordered color combinations.
 */
const OrderedColorCombinations = Object.freeze(WUBRG
  .concat(AllyColors)
  .concat(EnemyColors)
  .concat(ShardColors)
  .concat(WedgeColors)
  .concat(FourColors)
  .concat("WUBRG"));

export const Colorless = "C";
export const Multicolor = "multi";

export const Land = "land";
export const BasicLand = "basic land"

export const Special = "special";
export const Unknown = "unknown";

/**
 * @param {String} colors one or more card colors
 * @returns the individual color characters, sorted alphabetically
 */
function sortedColors(colors) {
  return colors.split('').sort().join('');
}

/**
 * A map from colors with the individual color codes sorted alphabetically
 * to the corresponding WUBRG-ordered color combination.
 */
export const ColorCombinationMap = Object.freeze(OrderedColorCombinations.reduce(
  (map, colors) => ({ ...map, [sortedColors(colors)]: colors }),
  /* initialValue = */ {}));

/**
 * An ordered list of color combinations and card groupings, with each 
 * individual color character sorted alphabetically.
 */
export const OrderedColorsAndGroups = Object.freeze(OrderedColorCombinations.map(sortedColors)
  .concat(
    Colorless,
    // a special column (e.g., order Lessons separately)
    Special, 
    Land,
    BasicLand
  ));

/**
 * Ordered color *groups* that include a generic {@link Multicolor} category for all multicolor cards, 
 * unlike {@link OrderedColorsAndGroups} which separates each individual color combinations/factions.
 */
export const OrderedColorGroups = Object.freeze(WUBRG
  .concat(
    Multicolor, 
    Colorless, 
    // a special column (e.g., order Lessons separately)
    Special, 
    Land, 
    BasicLand
  ));

export const OrderedRarities = Object.freeze(["mythic", "rare", "uncommon", "common", BasicLand]);

export const OrderedTierRatings = Object.freeze([
  { tier: "A+", rating: "10",  color: "lime"}, // 10: Bomb
  { tier: "A",  rating: "9.5", color: "greenyellow"},
  { tier: "A-", rating: "9",   color: "greenyellow"},
  { tier: "B+", rating: "8.5", color: "greenyellow"},
  { tier: "B",  rating: "8",   color: "greenyellow"},
  { tier: "",   rating: "7.5", color: "yellow"},
  { tier: "B-", rating: "7",   color: "yellow"},
  { tier: "",   rating: "6.5", color: "gold"},
  { tier: "C+", rating: "6",   color: "gold"}, // 6-9: Almost always play
  { tier: "",   rating: "5.5", color: "orange"},
  { tier: "C",  rating: "5",   color: "orange"},
  { tier: "C-", rating: "",    color: "orange"},
  { tier: "",   rating: "4.5", color: "#f88"},
  { tier: "D+", rating: "4",   color: "#f88"},
  { tier: "",   rating: "3.5", color: "#f88"},
  { tier: "D",  rating: "3",   color: "#f88"}, // 3-5: Filler
  { tier: "",   rating: "2.5", color: "#f57"},
  { tier: "D-", rating: "2",   color: "#f57"},
  { tier: "",   rating: "1.5", color: "#f57"},
  { tier: "F",  rating: "1",   color: "#f57"}, // 1-2: Bad/Sideboard
  { tier: "SB", rating: "SB",  color: "#ccc"},
]);

/**
 * OrderedTierRatings mapped to just the tier
 */
export const OrderedTiers = Object.freeze(OrderedTierRatings.map(t => t.tier).filter(t => t));

/**
 * OrderedTierRatings mapped to just the rating, filtering out empty ratings
 */
export const OrderedRatings = Object.freeze(OrderedTierRatings.map(t => t.rating).filter(r => r));

/**
 * Color symbols for mana sources
 */
export const ManaSourceColors = Object.freeze(WUBRG.concat("C"));

/**
 * Mapping from remastered set codes to the original set code(s)
 */
export const RemasteredSetCodes = Object.freeze({
  // Kaladesh Remastered: Kaladesh and Aether Revolt
  klr: [ "kld", "aer" ],
  // Amonkhet Remastered: Amonkhet and Hour of Devastation
  akr: [ "akh", "hou" ],
  // Shadows over Innistrad Remastered: Shadows over Innistrad and Eldritch Moon
  sir: [ "soi", "emn" ]
});

/**
 * Mapping from standard set codes to bonus sheet set codes
 */
export const BonusSheetSetCodes = Object.freeze({
  // STX -> STA (Strixhaven Mystical Archive)
  stx: [ "sta" ],
  // BRO -> BRR (The Brothers' War Retro Artifacts)
  bro: [ "brr" ],
  // SIR (Shadows over Innistrad Remastered) -> SIS (Shadows of the Past)
  sir: [ "sis" ],
  // MOM -> MUL (March of the Machine Multiverse Legends)
  mom: [ "mul" ],
  // WOE -> WOT (Wilds of Eldraine: Enchanting Tales)
  woe: [ "wot" ],
  // OTJ -> OTP (Breaking News) and BIG (The Big Score)
  otj: [ "otp", "big" ],
});

/**
 * A list of set codes that were drafted as Play Boosters
 */
export const PlayBoosterSetCodes = Object.freeze([
  //'lci', // Lost Caverns of Ixalan (not drafted as Play Boosters)
  'mkm', // Murders at Karlov Manor
  'otj', // Outlaws of Thunder Junction
  'mh3', // Modern Horizons 3
  'blb', // Bloomburrow
  'dsk', // Duskmourn
  'fdn', // Foundations
  'dft', // Aetherdrift
  'tdm', // Tarkir: Dragonstorm
]);

/**
 * The number of digits to use for displaying the 17Lands 
 * Games In /hand Win Rate.
 * 
 * For example, 3 digits of 0.54734 as a percentile means 54.7%
 */
export const GIH_WR_DIGITS = 3;

export const GIH_WR_PROPERTY    = "17lands_ever_drawn_win_rate";
export const GIH_COUNT_PROPERTY = "17lands_ever_drawn_game_count";
export const GIH_SET_PROPERTY   = "17lands_card_ratings_set";

export const RATING_SCORES_PROPERTY = "rating_scores";
export const RATING_TIER_PROPERTY   = "rating_tier";
export const RATING_SET_PROPERTY    = "rating_set";

/**
 * Basic land types and their color symbols (Excluding Wastes/Colorless)
 */
export const BasicLandColors = Object.freeze({
  "plains" : "W",
  "island" : "U",
  "swamp" : "B",
  "mountain" : "R",
  "forest" : "G"
});

/**
 * The standard basic land types (Excluding Wastes)
 */
 export const BasicLandTypes = Object.freeze(Object.keys(BasicLandColors));

/**
 * Fetch lands that fetch basic land *types* 
 * (not restricted to basic lands);
 * 
 * See https://mtg.fandom.com/wiki/Fetch_land
 */
 export const BasicTypeFetchLands = Object.freeze({
  // Miscellaneous
  //  ignore "Krosan Verge" -- Forest or Plains -- green to activate
  //  ignore "Flagstones of Trokair" -- Plains -- must be sacrificed
  //  ignore "Maze's End" -- fetches Gates
  // Mirage (Allied Fetch Lands)
  "flood plain":     [ "plains",   "island" ],
  "bad river":       [ "island",   "swamp" ],
  "rocky tar pit":   [ "swamp",    "mountain" ],
  "mountain valley": [ "mountain", "forest" ],
  "grasslands":      [ "forest",   "plains" ],
  // Onslaught (Allied Pain Fetch Lands)
  "flooded strand":    [ "plains",   "island" ],
  "polluted delta":    [ "island",   "swamp" ],
  "bloodstained mire": [ "swamp",    "mountain" ],
  "wooded foothills":  [ "mountain", "forest" ],
  "windswept heath":   [ "forest",   "plains" ],
  // Zendikar (Enemy Pain Fetch Lands)
  "marsh flats":       [ "plains",   "swamp" ],
  "scalding tarn":     [ "island",   "mountain" ],
  "verdant catacombs": [ "swamp",    "forest" ],
  "arid mesa":         [ "mountain", "plains" ],
  "misty rainforest":  [ "forest",   "island" ]
});

/**
 * Fetch lands that fetch basic lands;
 * 
 * See https://mtg.fandom.com/wiki/Fetch_land
 */
export const BasicFetchLands = Object.freeze({
  // Miscellaneous
  //  ignore "Blighted Woodland" -- green to activate
  //  "Myriad Landscape" -- two basic lands (same type) and colorless
  // Any Basic Land
  "fabled passage":       [ "plains", "island", "swamp", "mountain", "forest" ],
  "prismatic vista":      [ "plains", "island", "swamp", "mountain", "forest" ],
  "myriad landscape":     [ "plains", "island", "swamp", "mountain", "forest" ],
  "terminal moraine":     [ "plains", "island", "swamp", "mountain", "forest" ],
  "warped landscape":     [ "plains", "island", "swamp", "mountain", "forest" ],
  "terramorphic expanse": [ "plains", "island", "swamp", "mountain", "forest" ],
  "evolving wilds":       [ "plains", "island", "swamp", "mountain", "forest" ],
  "thawing glaciers":     [ "plains", "island", "swamp", "mountain", "forest" ],
  "escape tunnel":        [ "plains", "island", "swamp", "mountain", "forest" ],
  // Shards of Alara (Panoramas)
  "bant panorama":   [ "forest",   "plains",   "island" ],
  "esper panorama":  [ "plains",   "island",   "swamp" ],
  "grixis panorama": [ "island",   "swamp",    "mountain" ],
  "jund panorama":   [ "swamp",    "mountain", "forest" ],
  "naya panorama":   [ "mountain", "forest",   "plains" ],
  // Streets of New Capenna (Family Fetch Lands)
  "brokers hideout":     [ "forest",   "plains",   "island" ],
  "obscura storefront":  [ "plains",   "island",   "swamp" ],
  "maestros theater":    [ "island",   "swamp",    "mountain" ],
  "riveteers overlook":  [ "swamp",    "mountain", "forest" ],
  "cabaretti courtyard": [ "mountain", "forest",   "plains" ],
  // Modern Horizons 3 (Landscapes)
  "bountiful landscape":    [ "forest",   "island",   "mountain" ],
  "contaminated landscape": [ "plains",   "island",   "swamp" ],
  "deceptive landscape":    [ "plains",   "swamp",    "forest" ],
  "foreboding landscape":   [ "swamp",    "forest",   "island" ],
  "perilous landscape":     [ "island",   "mountain", "plains" ],
  "seething landscape":     [ "island",   "swamp",    "mountain" ],
  "shattered landscape":    [ "mountain", "plains",   "swamp" ],
  "sheltering landscape":   [ "mountain", "forest",   "plains" ],
  "tranquil landscape":     [ "forest",   "plains",   "island" ],
  "twisted landscape":      [ "swamp",    "mountain", "forest" ]
});
